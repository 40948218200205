import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import Text from "../../../../../components/text"
import Note from "../../../../../components/note"
import Columns from "../../../../../components/columns"
import Column from "../../../../../components/column"
import Textarea from "../../../../../components/textarea"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "tagebuch",
}

const Page = () => {
  const { submitForm, getAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const answer01 = getAnswer({ ...taskProps, taskId: "tagebuch-01" })
  const answer02 = getAnswer({ ...taskProps, taskId: "tagebuch-02" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
          taskId: "tagebuch-03",
        })
        navigate(
          "/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/tagebuch/brief-abschicken"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
      progress={progress}
    >
      <Seo title="Das Tagebuch weiterschreiben" />
      <Stack>
        <Heading as="h1" level={2}>
          Das Tagebuch weiterschreiben
        </Heading>

        <Paragraph>
          Nun bist du Hölderlin. Schlüpfe in seine Rolle und berichte der Mutter
          von den Ereignissen.
        </Paragraph>

        <Note variant="task">
          Schreibe in das untere Textfeld einen Brief an die Mutter.
        </Note>

        <Stack space={3}>
          {answer01 &&
            Object.entries(answer01).map(([id, content]) => (
              <Columns key={id} alignX="space-between" alignY="center">
                <Column width="content">
                  <Box
                    sx={{
                      fontSize: 6,
                      width: 12,
                      height: 12,
                      bg: "background",
                      borderWidth: 2,
                      color: "contrast",
                      borderColor: "border",
                      borderRadius: "lg",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {answer02 ? answer02[id] : "?"}
                  </Box>
                </Column>
                <Column>
                  <Text size={[3, 4]}>{content}</Text>
                </Column>
              </Columns>
            ))}
        </Stack>

        <Textarea
          id="letter"
          name="letter"
          label="Dein Brief"
          rows={10}
          placeholder="Liebe Mutter,...."
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
